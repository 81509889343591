@font-face {
  font-family: 'icons';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/icons/icons.eot');
  src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
    url('../fonts/icons/icons.woff') format('woff'),
    url('../fonts/icons/icons.ttf') format('truetype');
}

.Icon::before {
  display: inline-block;
  font-family: 'icons';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

// --replaceCSS
$icon-hamburger: '\EA01';
$icon-plus: '\EA02';

.Icon--hamburger::before { content: $icon-hamburger; }
.Icon--plus::before { content: $icon-plus; }
