.header {
  background-color: #7F7F7F;
  display: flex;
  height: 80px;
  position: relative;
  width: 100%;
}

.header-logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo {
  max-width: 260px;
  margin-top: 16px;
}

.logoMexico {
  margin-top: 16px;
  width: 107px;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .logoMexico {
    width: 80px;
  }
}

.header-text {
  align-items: center;
  color: white;
  display: flex;
  justify-content: flex-end;
  // text-transform: uppercase;
  width: 50%;
}

.imgMenu {
  cursor: pointer;
  font-size: 25px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  z-index: 2;
}

.header-menu-arrow {
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 50px solid transparent;
    height: 0;
    position: absolute;
    right: 16px;
    width: 0;
}

.header-menu {
    align-items: center;
    background-color: white;
    color: black;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 70px;
    width: 200px;
}

.menuLogo {
  height: 2.5rem;
  width: 2.5rem;
  color: #d0234b;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.menuLogo:hover {
  background-color: #eaecf4;
  cursor: pointer;
}
