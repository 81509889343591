.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: 99999;
  overflow: hidden;
}

.Modal-open--body {
  overflow: hidden;
}

.modal-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  h2 {
    text-align: center;
  }

  @include breakpoint(medium-only) {
    width: 80%;
  }
  
  @include breakpoint(small-only) {
    width: 80%;
  }
}

.wrapperButtonModal {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include breakpoint(small-only) {
    flex-direction: column;
  }
}

.modal-wrapper-component {
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  flex-wrap: wrap;
  max-height: 400px;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    border-radius: 6px;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d0234b;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    // border: 2px solid grey;
    border-radius: 6px;
  }
}

.modal-icon-close {
  text-align: right;
  width: 100%;
  z-index: 99999999;
}

.modal-close {
  color: #7F7F7F;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  padding: 2px;
  position: absolute;
  right: 25px;
  top: 20px;
  
  @include breakpoint(small-only) {
    right: 6%;
  }
}

/*         MODAL INPUT          */

.modalInput {
  align-items: center;
  background-color: white;
  border-radius: 5px;
  color: #7F7F7F;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  width: 800px;

  input {
    border-radius: 5px;
    border: 2px solid #7F7F7F;
    flex: 2 1;
    letter-spacing: 2px;
    margin: 10px auto;
    padding: 10px;
    width: 85%;
  }

  h2 {
    width: 100%;
  }

  h3 {
    color: #ca4757;
    font-weight: bold;
  }

  .textAreaModal {
    border-radius: 5px;
    border: 2px solid #7F7F7F;
    flex: 2 1;
    letter-spacing: 2px;
    margin: 10px auto;
    padding: 10px;
    width: 85%;
  }
}