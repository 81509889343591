html{
  background-color: #3d3d3d;
  font-family: 'Lato';
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.viewer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    width: 90%;
  }
}

.aviso-privacidad {
  height: 100%;
  width: 100%;
}

.download {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  // text-align: center;
  width: 100%;
}

.icons {
  font-size: 20px;
}

.download-text {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-wrap: break-word;
  padding-bottom: 80px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 80px;
}

.listaReferenciasUsuario {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 80%;
}

.arrow-menu {
  border-bottom: 15px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  height: 0;
  position: absolute;
  right: 26px;
  top: 70px;
  width: 0;
  z-index: 1;
}

.aviso-privacidad-text {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-wrap: break-word;
  padding-bottom: 80px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 80px;

  p {
    text-align: justify;
  }
}

.errorPreView {
  padding: 20px;
  height: 100px;
}

.user {
  .loginMainSection {
    padding-top: 30px;
    align-items: initial;
  }

  min-height: 100%;
  width: 100%;
}

.loginMainSection {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  min-height: 86vh;
  width: 100%;
}

.loginMainSection-Wrapper {
  display: flex;
  flex-direction: row;

  @include breakpoint(small-only) {
    display: initial;
    text-align: center;
  }
}

.loginMainSection-Wrapper-center {
  text-align: center;
  width: 100%;
}

.loginWrapper {
  width: 100%;
  height: auto;
  margin: 0px 5%;
  background-color: #7F7F7F;
  border: 1px solid #3d3d3d;
  border-radius: 5px;
}

.loginHeader {
  padding: 5px;
  padding-left: 10px;
  border-bottom: 2px solid #3d3d3d;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.addFooter {
  @include breakpoint(small-only) {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
}

.title {
  font-weight: bold;
  letter-spacing: 0px;
  border-bottom: none;

  @include breakpoint(small-only) {
    text-align: center;
  }
}

.wrappListClient {
  margin-bottom: 10px;
}

.loginContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;

  @include breakpoint(small-only) {
    display: initial;
  }
}

.accessContainer {
  flex: 1 0 0;
  margin-bottom: 20px;
  max-width: 400px;

  @include breakpoint(small-only) {
    max-width: initial;
  }
}

.form-control {
  min-height: 37px;
}

.btns-back {
  display: flex;

  @include breakpoint(small-only) {
    flex-direction: column;

    .addListBtn {
      width: 90%;
    }
  }
}

.page-item {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.wraptitleListClient {
  display: flex;
}

.titleListClient {
  color: #d0234b;
  text-align: center;
  width: 40%;
}

.spaceTitleListClient {
  width: 20%;
}

.wrappSelectClients {
  display: flex;
  height: 200px;
  margin-bottom: 20px;
}

.listClient {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 40%;
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    border-radius: 6px;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d0234b;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    // border: 2px solid grey;
    border-radius: 6px;
  }
}

.optionsListClient {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 20%;
}

.optionClient {
  cursor: pointer;
  padding: 10px 0 10px 0;
  text-align: center;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid;
  }

  &:hover {
    background: green;
    color: white;
  }
}

.selectedClient {
  background: green;
  color: white;
}

/* .iconGroupClientTable {
  text-align: center;
}

.table {
  text-align: center;
  th {
    text-align: center;
  }
} */

.enableElement {
  opacity: 0.0;
  pointer-events: none;
}

.popUp {
  align-items: center;
  background: black;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.popUp-wrapper-success {
  align-items: center;
  background-color: #1cc88a;
  border-radius: 5px;
  color: white;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  position: fixed;
  width: 40%;
  z-index: 9999999;

  @include breakpoint(medium-only) {
    width: 60%;
  }

  @include breakpoint(small-only) {
    width: 95%;
  }
}

.popUp-wrapper-error {
  align-items: center;
  background-color: #d0234b;
  border-radius: 5px;
  color: white;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  position: fixed;
  width: 40%;
  z-index: 9999999;

  @include breakpoint(medium-only) {
    width: 60%;
  }

  @include breakpoint(small-only) {
    width: 95%;
  }
}

.btns {
  display: flex;

  @include breakpoint(small-only) {
    flex-direction: column;

    .addListBtn {
      width: 100%;
    }
  }
}

.btnsBack {
  display: flex;

  @include breakpoint(small-only) {
    flex-direction: column;

    .addListBtn {
      width: 100%;
    }
  }
}

.filesContainer {
  flex: 1 0 0;
  width: 100%;
  align-self: flex-start;
  padding: 0px 10px;
}

.logoImage {
  width: 50%;
  margin: 0% 25%;
  align-items: center;
  justify-content: center;
}

.formGroup {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 1px;
  width: 100%;

  input {
    // border: 1px solid #FFFFFF;
    border-bottom: 1px solid black;
    border-left: none;
    border-radius: 0px 5px 5px 0px;
    border-right: 1px solid black;
    border-top: 1px solid black;
    flex: 2 1 0;
    letter-spacing: 2px;
    padding: 10px;
  }
}

.formGroup-disabled {
  input {
    border-bottom: 2px solid #FFC312;
    border-right: 2px solid #FFC312;
    border-top: 2px solid #FFC312;
  }
}

.input-checkbox-wrapper {
  display: flex;
}

.input-checkbox {
  height: 19px;
  margin-top: 2.5px;
  width: 35px;
}

.input-checkbox-text {
  text-align: initial;
}

.formGroup-error {
  color: #FFC312;
}

.userIcon {
  display: flex;
  flex-direction: row;
  span {
    background-color: #FFC312;
    color: black;
    padding: 10px;
    border-radius: 5px 0px 0px 5px;
    flex: 1 1 0;
  }
}

.buttonLogin {
  border-radius: 3px;
  border: 1px solid;
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  margin-bottom: 10px;
  margin-top: 5px;
  background-color: #FFC312;
  float: right;
  width: 100px;
  border: 1px solid #FFC312;
  letter-spacing: 1px;
  margin-right: 4%;
}

.wrapperBtnReferences {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.buttonMoreReferences {
  border-radius: 3px;
  border: 1px solid;
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  margin-bottom: 10px;
  margin-top: 5px;
  background-color: #FFC312;
  float: right;
  width: 230px;
  border: 1px solid #FFC312;
  letter-spacing: 1px;
  margin-right: 4%;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.contactInformation {
  align-self: baseline;
  justify-content: center;
  margin: 0px;
}

.filesContainerBody {
  width: 100%;
  // max-height: 250px;
  max-height: 480px;
  overflow-y: auto;
  background-color: #FFFFFF;
  color: #3d3d3d;

  &::-webkit-scrollbar {
    border-radius: 6px;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d0234b;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    // border: 2px solid grey;
    border-radius: 6px;
  }

  ul {
    list-style-type: none;
    padding: 0px;
  }

  .document {
    align-items: center;
    border-bottom: none;
    border-radius: 3px;
    border: 1px solid #3d3d3d;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 5px;
    padding: 10px 10px;
  }

  /* .document:hover {
    background-color: #7F7F7F;
    color: #FFFFFF;
    cursor: pointer;
  } */

  li:last-child {
    border-bottom: 1px solid #3d3d3d;
  }
}