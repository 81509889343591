.btn {
    border-color: buttonface;
    border-radius: 20px;
    border: 1px solid;
    color: white;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;
}

.primary {
    background: red;
    border-color: red;
    color: white;
}