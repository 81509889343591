.input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.input-title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    margin-top: 5px;
}

.input-content {
    border-color: black;
    border-radius: 5px;
    border: 1px solid;
    font-size: 20px;
    padding-left: 10px;
}