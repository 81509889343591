@mixin breakpoint($breakpoint) {
    @if $breakpoint == 'small-only' {
      @media (max-width: $bp-medium - 1) {
        @content;
      }
    } @else if $breakpoint == 'medium' {
      @media (min-width: $bp-medium) {
        @content;
      }
    } @else if $breakpoint == 'medium-print' {
      @media print, (min-width: $bp-medium) {
        @content;
      }
    } @else if $breakpoint == 'medium-only' {
      @media (min-width: $bp-medium) and (max-width: $bp-medium-high - 1) {
        @content;
      }
    } @else if $breakpoint == 'medium-and-lower' {
      @media (max-width: $bp-medium-high - 1) {
        @content;
      }
    } @else if $breakpoint == 'medium-high' {
      @media (min-width: $bp-medium-high) {
        @content;
      }
    } @else if $breakpoint == 'medium-high-only' {
      @media (min-width: $bp-medium-high) and (max-width: $bp-large - 1) {
        @content;
      }
    } @else if $breakpoint == 'medium-high-and-lower' {
      @media (max-width: $bp-large - 1) {
        @content;
      }
    } @else if $breakpoint == 'medium-high-print' {
      @media print, (min-width: $bp-medium-high) {
        @content;
      }
    } @else if $breakpoint == 'large' {
      @media (min-width: $bp-large) {
        @content;
      }
    } @else if $breakpoint == 'large-only' {
      @media (min-width: $bp-large) and (max-width: $bp-large - 1) {
        @content;
      }
    } @else if $breakpoint == 'xlarge' {
      @media (min-width: $bp-xlarge) {
        @content;
      }
    } @else if $breakpoint == 'homepage-max' {
      @media (min-width: $bp-homepage-max) {
        @content;
      }
    } @else {
      @error 'No value could be retrieved for ' + $breakpoint;
    }
  }

  @mixin generate-grid() {
    @include generate-cols(6, $gutter-small, 'Sm', true);
  
    @include breakpoint(medium) {
      @include generate-cols(12, $gutter-medium, 'Md', true);
    }
  
    @include breakpoint(medium-high) {
      @include generate-cols(12, $gutter-medium-high, 'MdHigh');
    }
  
    @include breakpoint(large) {
      @include generate-cols(12, $gutter-large, 'Lg');
    }
  }