.signingSection {
	width: 100%;
	height: auto;
	margin: 0px 30%;
	background-color: #7F7F7F;
	border: 1px solid #3d3d3d;
	border-radius: 5px;
}

.signingBody {
	margin: 5px 10px;
}


///////////////////////// ADD CLIENT STYLES ///////////////

.addSection {
	align-self: baseline;
	background-color: #7F7F7F;
	border-radius: 5px;
	height: auto;
	margin-top: 20px;
	margin: 0px 3%;
  margin-top: 60px;
  width: 100%;
}

@media only screen and (max-width: 600px){
  .addSection {
    margin: 0px 2%;
  }

  .signingSection {
   margin: 0px 2%;
  }
}


.addBody {
	width: 90%;
	margin: 10px auto;
}

.textPass {
  margin: 0;
}

.addformGroup {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 1px;
  width: 100%;

  label {
  	font-weight: bold;
  }

  select {
    background: white;
    border-radius: 5px;
    border: 1px solid black;
    flex: 2 1;
    letter-spacing: 2px;
    margin: 5px 0px;
    outline: none;
    padding: 10px;

  }

  input {
    // border: 1px solid #FFFFFF;
    border: 1px solid black;
    border-radius: 5px;
    flex: 2 1 0;
    letter-spacing: 2px;
    margin: 5px 0px;
    outline: none;
    padding: 10px;
  }
}

.addformGroup {
  .addformGroup-error-add {
    border: 2px solid #FFC312;
  }
}

.addformGroup-error {
  input {
    border-bottom: 2px solid #FFC312;
    border-right: 2px solid #FFC312;
    border-top: 2px solid #FFC312;
  }
}

.addformGroup{
  label{
    margin-left: 5% ;
  }
  .anio{
    width: 30%;
    margin-left: 5%;
  }
}

.addformGroupUser{
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 1px;
  width: 100%;

  label {
  	font-weight: bold;
  }

  select {
    background: white;
    border-radius: 5px;
    border: 1px solid black;
    flex: 2 1;
    letter-spacing: 2px;
    margin: 5px 0px;
    outline: none;
    padding: 10px;

  }

  input {
    // border: 1px solid #FFFFFF;
    border: 1px solid black;
    border-radius: 5px;
    flex: 2 1 0;
    letter-spacing: 2px;
    margin: 5px 0px;
    outline: none;
    padding: 10px;
  }

  .anio{
    width: 30%;
    margin-left: 5%;
  }
}

.permisions {
  border-bottom: 1px solid;
  height: 80px;
  margin-bottom: 10px;
}

.permisionsClient {
  border-bottom: 1px solid;
  margin-bottom: 10px;
}

.groupCheckBox {
  display: flex;
}

.wrap-checbox {
  align-items: center;
  display: flex;
  justify-content: center;

  input[type=checkbox] {
    height: 20px;
    width: 20px;
  }

  label {
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 20px;
  }
}

///////////////////////// ADD DOCS STYLES ///////////////

.addDocs {
  width: 90%;
  margin: 20px auto;

  input {
    border-radius: 5px;
    padding: 10px;
    flex: 2 1 0;
    letter-spacing: 2px;
    margin: 5px 0px;
    width: 97%;
  }
}

.browseSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.uploadTitle {
  background-color: #FFC312;
  border-radius: 5px 0px 0px 5px;
  color: black;
  cursor: pointer;
  margin: 5px 0px;
  padding: 10px 12px;
}

.uploadDesabled {
  pointer-events: none;
  opacity: 0.3;
}

.browseInput {
  display: flex;
  width: 91%;
  position: relative;
  cursor: pointer;

  input {
    border-radius: 0px;
    width: 100%;

  }
}

.browseTitle {
  padding: 10px 12px;
  background-color: #e9ecef;
  color: black;
  margin: 5px 0px;
  width: 30px;
  border-radius: 0px 5px 5px 0px;
}

.customLabel {
  position: absolute;
  top: 7.6px;
  width: calc(100% - 27px);
  z-index: 1;
  height: auto;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #3d3d3d;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0 5px 5px 0px;
}

.customInput {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.loadedFileSection {
  width: auto;
  height: auto;
  margin: 30px 80px;
  background-color: white;
  border: 1px solid white;
  border-radius: 6px;

  .loadedFileSection-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
    border-bottom: 2px solid #ced4da;
  }
}

.loadedFileCard {
  background-color: #FFC312;
  border-radius: 5px;
  border: 1px solid #FFC312;
  flex: 1 0 30%;
  margin: 10px 5px;
  min-height: 55px;
  // min-width: 250px;

  .loadedFileSection-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 10px;
    text-align: center;

    i {
      font-size: 40px;
      color: black;
    }

    i:last-child {
      font-size: 20px;
      cursor: pointer;
    }

    label {
      font-size: 18px;
      color: black;
      align-self: center;
      margin-right: 10px;
    }
  }
}

.removeFilesSection {
  display: flex;
  justify-content: center;
  margin: 10px 0px;

  .removeAllBtn {
    border-radius: 5px;
    font-size: 18px;
    color: white;
    height: 35px;
    margin: 10px auto;
    background-color: #ca4757;
    float: right;
    width: 35%;
    border: 1px solid #ca4757;
    letter-spacing: 1px;
    cursor: pointer;
  }
}

/////////////////////////   ADD LIST CLIENTS    //////////////////////////

.addClientSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.addListBtn {
  border-radius: 10px;
  border: 1px solid;
  font-size: 15px;
  font-weight: bold;
  color: black;
  height: 35px;
  margin: 10px auto;
  background-color: #FFC312;
  float: right;
  width: 35%;
  min-width: 180px;
  border: 1px solid #FFC312;
  letter-spacing: 1px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .addListBtn {
    width: 100%;
  }
}

.clientListBox {
  width: 100%;
  height: auto;
  color: #212529;
  font-size: 20px;

  ul {
    padding: 0px;
    list-style-type: none;

    span {
      padding-left: 10px;
    }
  }

  .contentClient {
    display: flex;
    justify-content: space-between;
    width: 94%;
    height: 60px;
    background-color: white;
    padding: 10px 0px;
    border: 0.5px solid black;
    border-radius: 3px;
    padding: 15px 20px;

    @include breakpoint(small-only) {
      width: 100%;
      padding: 0px;
      align-items: center;
      font-size: 16px;
      span {
        font-size: 12px;
        display: flex;
      }
    }

    .iconGroup {
      color: #4bbb94;
      cursor: pointer;
      i {
        margin: 0px 10px;
      }

      i:last-child {
        color: #ca4757;
      }

      @include breakpoint(small-only) {
        i {
          margin: 0px 5px;
        }
      }
    }
  }

  .clientGroup {
    display: flex;
    justify-content: space-between;
    width: 94%;
    min-height: 60px;
    height: auto;
    background-color: white;
    padding: 10px 0px;
    border: 0.5px solid black;
    border-radius: 3px;
    padding: 15px 20px;
    margin-bottom: 10px;

    .iconGroup {
      align-items: center;
      color: #4bbb94;
      cursor: pointer;
      display: flex;
      justify-content: center;

      i {
        margin: 0px 10px;
      }

      i:last-child {
        color: #ca4757;
      }
    }
  }
  @media only screen and (max-width: 600px) {
  .clientGroup {
     width: 80%;
    font-size: 12px;

    .iconGroup{
      i{
        margin: 0px 3px;
      font-size: 20px;
      display: inline;
      }
    }
  }
}
}
.iconGroupClientTable {
  i {
    cursor: pointer;
    margin: 0px 5px;
  }
  i:nth-child(1) {
    color: #4bbb94;
  }
  i:nth-child(2) {
    color: #f6c23e;
  }
  i:nth-child(3) {
    color: #e74a3b;
  }
  i:nth-child(4) {
    color:#f6c23e;
  }
}

.iconGroupUserTable {
  i {
    cursor: pointer;
    margin: 0px 5px;
  }
  i:nth-child(1) {
    color: #f6c23e;
  }
  i:nth-child(2) {
    color: #e74a3b;
  }
}

.iconGroupExtensionTable {
  i {
    color: #e74a3b;
    cursor: pointer;
    margin: 0px 5px;
  }
}

.buttonAddSize {
  width: 160px;
  margin-right: 10px;
  i {
    padding-right: 5px;
  }
}

.auto-width {
  width: auto;
}

.iconGroupDocsTable {
  i {
    cursor: pointer;
    margin: 0px 5px;
  }
  i:nth-child(1) {
    color: #4e73df;
  }
  i:nth-child(2) {
    color: #4bbb94;
  }
  i:nth-child(3) {
    color: #e74a3b;
  }
}

.dataTables_length  > label {
  width: 97%;
}

.dataTables_filter > label {
  width: 100%;
  ::-webkit-input-placeholder {
     color:transparent;
  }

  :-moz-placeholder {
     color:transparent; 
  }

  ::-moz-placeholder { 
     color:transparent;
  }

  :-ms-input-placeholder {
     color:transparent;
  }
  & > input { 
    margin-top: 0px;
  }
}